@if (isLoading && !package) {
  <div class="hotel-details-container">
    <mat-progress-bar mode="indeterminate" />
  </div>
}
@if (package) {
  <div class="hotel-details-container">
    <div class="hotel-details-body px-4 py-3">
      @if (package.hotel) {
        <app-kz-desktop-hotel-card [hotel]="hotelData" />
      }
      @if (package.hotel) {
        <div class="bg-kz-layout-featured my-3 p-2">
          <span class="text-[0.8rem]"
            >{{ 'Infants Ages' | translate }}: {{ 'To' | translate | lowercase }} {{ package.hotel.infantMaxAge }},
            {{ 'Children Ages' | translate }}: {{ package.hotel.childMinAge }} - {{ package.hotel.childMaxAge }},
            @if (package.hotel.teenMinAge && package.hotel.teenMaxAge) {
              {{ 'Teens Ages' | translate }}: {{ package.hotel.teenMinAge }} - {{ package.hotel.teenMaxAge }},
            }
            {{ 'Adults Ages' | translate }}: {{ 'From' | translate | lowercase }} {{ package.hotel.adultMinAge }}</span
          >
        </div>
      }
      <!-- @note decided to hide, task: https://app.clickup.com/t/2468594/FTR-4658 -->
      <mat-checkbox
        color="primary"
        [disabled]="!package.insurancePrice?.optional"
        [ngStyle]="{ display: package.insurancePrice ? 'block' : 'none' }"
        [(ngModel)]="includedInPackagePrice"
        (ngModelChange)="onchange($event)"
      >
        <span style="font-size: 0.9em">
          {{ package.insurancePrice?.name ?? '<<< INSURANCE NAME NOT FOUND >>>' }}
          <!-- @note - no need at this moment
                ({{ package.insurancePrice?.price | kzPrice: package.priceCurrency }}) -->
          {{ package.insurancePrice?.actualStartDate | date: 'dd.MM.YYYY' }} -
          {{ package.insurancePrice?.actualEndDate | date: 'dd.MM.YYYY' }}
        </span>
      </mat-checkbox>
      <div>
        @for (extraService of package.extraServices; track extraService.extraServiceCode) {
          @if (extraService.extraServiceCode && extraService.extraServiceName) {
            <h3 class="text-kz-primary">
              {{ extraService.extraServiceName | translate }}
            </h3>
            <app-kz-desktop-select
              optionLabel="displayName"
              optionValue="extraServicePriceCode"
              placeholder="Without extra services"
              class="mb-2"
              [options]="extraService.prices ?? []"
              [typedToken]="extraServicePriceTypedToken"
              [value]="extraService.includedPriceCode"
              [showClear]="!extraService.isMandatory"
              (selectedOptionData)="onExtraServiceSelect(extraService.extraServiceCode, $event)"
            >
              <ng-template #templateSelected let-option>
                <div class="text-nowrap text-ellipsis overflow-hidden">
                  {{ option.displayName ?? '' | translate }}
                </div>
              </ng-template>
              <ng-template #templateOptionLabel let-option>
                {{ option.displayName ?? '' | translate }}
                <!-- 
                @note - removed - https://app.clickup.com/t/2468594/REQ-6046
                @if (option.extraPrice) {
                  <span>
                    ({{ 'For adults' | translate }}: {{ option.extraPrice | kzPrice: package.priceCurrency }})</span
                  >
                }
                 -->
              </ng-template>
            </app-kz-desktop-select>
            @if (
              { includedPriceCode: extraService.includedPriceCode, extraServiceCode: extraService.extraServiceCode }
                | runFunction: getSelectedExtraServiceDataFn;
              as extraServicePrice
            ) {
              <div class="flex flex-col gap-2">
                <!-- 
                @note  - https://app.clickup.com/t/2468594/REQ-6172
                @if (extraServicePrice.extraServicePriceCode) {
                  <div>
                    <span class="text-kz-primary font-bold">{{ 'Promo code' | translate }}:</span>
                    {{ extraServicePrice.extraServicePriceCode }}
                  </div>
                } -->
                @if (extraServicePrice.additionalInfo || extraServicePrice?.displayName) {
                  <div>
                    <span class="text-kz-primary font-bold">{{ 'Description' | translate }}:</span>
                    {{ extraServicePrice.additionalInfo ?? extraServicePrice?.displayName }}
                  </div>
                }
                @if (extraServicePrice.restrictionInfo) {
                  <div>
                    <span class="text-kz-primary font-bold">{{ 'Term' | translate }}:</span>
                    {{ extraServicePrice.restrictionInfo }}
                  </div>
                }
              </div>
            }
          }
        }
      </div>
      <div class="bg-kz-layout-featured price-details p-2 my-3 relative">
        @if (isLoading) {
          <div class="absolute top-0 left-0 w-full">
            <mat-progress-bar mode="indeterminate" />
          </div>
        }
        <div>
          {{ 'Amount to Pay' | translate }}
          <div>
            {{ (package.totalPrice ?? 0) - (package.agentCommissionAmount ?? 0) | kzPrice: package.priceCurrency }}
          </div>
        </div>
        <div>
          {{ "Agent's Commission" | translate }}
          <div>{{ package.agentCommissionPercent }}%</div>
        </div>
        <div>
          {{ 'Commission Amount' | translate }}
          <div>{{ package.agentCommissionAmount | kzPrice: package.priceCurrency }}</div>
        </div>
        <div class="text-kz-primary font-bold">
          {{ 'Total' | translate }}
          <div>{{ package.totalPrice | kzPrice: package.priceCurrency }}</div>
        </div>
      </div>
    </div>

    <ng-template #modalFooterTemplateRef>
      <div class="py-3 flex items-center justify-center">
        <button
          mat-flat-button
          color="primary"
          class="medium-size mt-2 w-[15rem!important]"
          type="submit"
          [loading]="isLoading"
          [disabled]="!allowAddToCart"
          (click)="onAddCart()"
        >
          <mat-icon svgIcon="cart" />
          {{ package.totalPrice ?? 0 | kzPrice: package.priceCurrency }}
        </button>
      </div>
    </ng-template>
  </div>
}
