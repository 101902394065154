import { Injectable } from '@angular/core';
import { GetCountriesResponse, Hotel, SearchOnlyHotelPackagesResponse } from '@appTypes/api.types';
import { DistrictsTreeItem, IOnlyHotelPackage } from '@appTypes/redefined-types';
import { IS_MOBILE, deepClean, hotelPackageExtraParamsProcess } from '@core/misc/misc.utils';
import { DataLoadStatusModel, RequestStateModel } from '@core/store/store.models';
import { RequestStateParams } from '@core/store/store.types';
import { SVGIcon, SVG_ICONS } from '@data/svg-icons.data';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { isEmpty } from 'lodash-es';
import { catchError, tap, throwError } from 'rxjs';
import { AppPersistedState } from 'src/app/state/app.persisted.state';
import { HotelsCommonService } from '../services/hotels-common.service';
import {
  hotelsGetDestinationCountries,
  hotelsGetHotels,
  hotelsGetLocations,
  hotelsOpenDetails,
  hotelsReset,
  hotelsResetResult,
  hotelsSearchHotels
} from './hotels.actions';
import { HotelsSearchFormDataModel, HotelsStateModel } from './hotels.model';

const defaults = new HotelsStateModel();

@State<HotelsStateModel>({
  name: 'hotels',
  defaults
})
@Injectable()
export class HotelsState {
  constructor(private common: HotelsCommonService) {}

  @Action(hotelsReset)
  hotelsReset({ setState }: StateContext<HotelsStateModel>) {
    return setState(defaults);
  }
  @Action(hotelsSearchHotels, { cancelUncompleted: true })
  hotelsSearchHotels(
    { setState, getState, patchState }: StateContext<HotelsStateModel>,
    { paging }: hotelsSearchHotels
  ) {
    const {
      searchResults: { loadState },
      searchForm
    } = getState();
    if (loadState.status === 'loading') return;

    if (!searchForm.model) return;
    const payload = deepClean(searchForm.model);
    if (isEmpty(payload)) return;
    if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.show();

    if (!paging?.pagingId)
      patchState({
        searchResults: new RequestStateModel<SearchOnlyHotelPackagesResponse>(new DataLoadStatusModel('loading'))
      });
    else
      setState(
        patch<HotelsStateModel>({
          searchResults: patch<RequestStateModel<SearchOnlyHotelPackagesResponse>>({
            loadState: new DataLoadStatusModel('loading')
          })
        })
      );

    const pageRowCount = paging?.pageRowCount ? paging.pageRowCount : 25;
    const searchCurrency = this.common.storeService.selectSnapshot(AppPersistedState.activedCurrency);
    return this.common.api.searchHotels({ ...payload, ...paging, pageRowCount: pageRowCount, searchCurrency }).pipe(
      tap((response) => {
        patchState({
          searchResults:
            !paging?.pagingId && !response.packages?.length
              ? new RequestStateModel<SearchOnlyHotelPackagesResponse>(
                  new DataLoadStatusModel('error', 'Data not found to show. try searching with different parameters')
                )
              : new RequestStateModel<SearchOnlyHotelPackagesResponse>({
                  ...response,
                  packages: response.packages?.map((e) => ({
                    ...e,
                    hotel: e.hotel
                      ? hotelPackageExtraParamsProcess({ childrenAges: payload.childrenAges ?? [] }, [e.hotel])[0]
                      : undefined
                  }))
                })
        });
        if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.hide();
      }),
      catchError((e) => {
        if (!paging?.pagingId)
          patchState({
            searchResults: new RequestStateModel<SearchOnlyHotelPackagesResponse>(
              new DataLoadStatusModel('error', e?.displayErrorMessage)
            )
          });
        else
          setState(
            patch<HotelsStateModel>({
              searchResults: patch<RequestStateModel<SearchOnlyHotelPackagesResponse>>({
                loadState: new DataLoadStatusModel('error', e?.displayErrorMessage)
              })
            })
          );
        if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.hide();
        return throwError(() => e);
      })
    );
  }

  @Action(hotelsGetLocations, { cancelUncompleted: true })
  hotelsGetLocations({ getState, setState }: StateContext<HotelsStateModel>, { refresh, params }: hotelsGetLocations) {
    const {
      searchFormData: { locations }
    } = getState();
    if (locations.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<DistrictsTreeItem[]>) => {
      setState(
        patch<HotelsStateModel>({
          searchFormData: patch<HotelsSearchFormDataModel>({ locations: new RequestStateModel(stateParams) })
        })
      );
    };

    params = deepClean(params);

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getArrivalDistricts(params).pipe(
      tap((response) => updateState(response)),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }

  @Action(hotelsGetHotels, { cancelUncompleted: true })
  hotelsGetHotels({ getState, setState }: StateContext<HotelsStateModel>, { refresh, params }: hotelsGetHotels) {
    const {
      searchFormData: { hotels }
    } = getState();

    if (hotels.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<Hotel[]>) => {
      setState(
        patch<HotelsStateModel>({
          searchFormData: patch<HotelsSearchFormDataModel>({ hotels: new RequestStateModel(stateParams) })
        })
      );
    };

    params = deepClean(params);

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getHotels(params).pipe(
      tap((response) => {
        response.sort((a, b) => {
          const hotelNameA = a?.hotelName?.toUpperCase();
          const hotelNameB = b?.hotelName?.toUpperCase();
          return hotelNameA && hotelNameB ? hotelNameA.localeCompare(hotelNameB) : 0;
        });
        updateState(response);
      }),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }

  @Action(hotelsGetDestinationCountries, { cancelUncompleted: true })
  hotelsGetDestinationCountries(
    { getState, setState }: StateContext<HotelsStateModel>,
    { refresh }: hotelsGetDestinationCountries
  ) {
    const {
      searchFormData: { destinationCountries }
    } = getState();

    if (destinationCountries.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<GetCountriesResponse>) => {
      setState(
        patch<HotelsStateModel>({
          searchFormData: patch<HotelsSearchFormDataModel>({ destinationCountries: new RequestStateModel(stateParams) })
        })
      );
    };

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getDestinationCountries({ onlyHotels: true }).pipe(
      tap((response) => updateState(response)),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }
  @Action(hotelsResetResult)
  hotelsResetResult({ patchState }: StateContext<HotelsStateModel>) {
    patchState({
      searchResults: new RequestStateModel<SearchOnlyHotelPackagesResponse>()
    });
  }
  @Action(hotelsOpenDetails, { cancelUncompleted: true })
  hotelsOpenDetails(ctx: StateContext<HotelsStateModel>, { data }: hotelsOpenDetails) {
    if (!data.id) return;
    this.common.openDetails(data);
    return;
  }
  /* Selectors */

  @Selector([HotelsState])
  static destinationCountries({ searchFormData: { destinationCountries } }: HotelsStateModel) {
    return destinationCountries;
  }

  @Selector([HotelsState])
  static destinationCountriesWithFlagIcon({ searchFormData: { destinationCountries } }: HotelsStateModel) {
    return {
      ...destinationCountries,
      response: destinationCountries.response?.countries?.length
        ? destinationCountries.response.countries.map((c) => {
            const icon = c?.countryCode ? (`flag-${c.countryCode.toLowerCase()}` as SVGIcon) : null;
            return {
              ...c,
              icon: icon ? (SVG_ICONS?.[icon] ? icon : 'map-marker') : 'map-marker'
            };
          })
        : null
    };
  }

  static findDestinationCountryName(code: string) {
    return createSelector(
      [HotelsState],
      ({ searchFormData: { destinationCountries } }: HotelsStateModel): string | null => {
        if (!destinationCountries.response?.countries?.length) return null;
        return destinationCountries.response?.countries.find((c) => c.countryCode === code)?.countryName ?? null;
      }
    );
  }

  static getHotelExtraParams(id: string) {
    return createSelector([HotelsState], ({ searchResults: { response } }: HotelsStateModel) => {
      return (response?.packages as IOnlyHotelPackage[])?.find((e) => e.id === id)?.hotel?._extraParams;
    });
  }

  @Selector([HotelsState])
  static searchFormData({ searchForm: { model } }: HotelsStateModel) {
    return model;
  }
  @Selector([HotelsState])
  static searchResults({ searchResults }: HotelsStateModel) {
    return searchResults;
  }
  @Selector([HotelsState])
  static isResult({ searchResults }: HotelsStateModel) {
    return Boolean(searchResults?.response?.packages?.length);
  }
  @Selector([HotelsState])
  static locations({ searchFormData: { locations } }: HotelsStateModel) {
    return locations;
  }
  @Selector([HotelsState])
  static hotels({ searchFormData: { hotels } }: HotelsStateModel) {
    return hotels;
  }
  @Selector([HotelsState])
  static searchLoadStatus({ searchResults: { loadState } }: HotelsStateModel): DataLoadStatusModel {
    return loadState;
  }
}
