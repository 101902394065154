<div class="relative">
  <div class="p-4">
    <div>
      <p class="mb-5">
        Инструкция по бронированию отелей (<a
          class="font-semibold"
          href="https://kazunion.com/countries/oae/service/novyiy-onlayn-oteli.html"
          target="_blank"
          >ссылка</a
        >)
      </p>

      <div class="my-3">
        <p class="font-semibold">В стоимость тура включено:</p>
        <ul class="m-0">
          <li>проживание в выбранном отеле с указанной концепцией питания</li>
          <li>комиссия агентства</li>
          <li>страховка (добровольная)</li>
        </ul>
      </div>
    </div>
  </div>
</div>
