@if (service.formStatus$ | async; as formStatus) {
  @if (formStatus.status) {
    @if (service.formGroup.controls; as formDataControl) {
      <app-kz-mobile-search-button
        [header]="formDataControl.countryCode.value | runFunction: findCountryNameFn"
        (click)="formStatus.status !== 'loading' ? openSearchForm() : null"
      >
        <span>
          {{ formDataControl.checkInDate.value | date: 'MMM dd' }}
          —
          {{ formDataControl.checkOutDate.value | date: 'MMM dd' }}
        </span>
        <span>
          {{ passengersInfo }}
        </span>
        @if (formDataControl.mealPlans.value) {
          <span>
            {{ formDataControl.mealPlans.value.join(', ') }}
          </span>
        }
      </app-kz-mobile-search-button>
    }
  } @else {
    <ng-container [ngTemplateOutlet]="searchFormRef" />
  }
  <ng-template #searchFormRef>
    <div class="relative">
      <form
        class="flex flex-col px-2 pt-5 pb-36 relative"
        style="gap: 4px"
        [formGroup]="service.formGroup"
        [ngxsForm]="service.ngxsForm"
        (ngSubmit)="service.onSubmit(); scrollTop()"
      >
        <app-kz-mobile-select
          optionLabel="countryName"
          optionValue="countryCode"
          optionIcon="icon"
          formControlName="countryCode"
          placeholder="Country"
          icon="map-marker"
          [typedToken]="service.countriesTypedToken"
          [options$]="service.countries$"
          [iconByOption]="true"
          [extraOptions]="service.countriesExtraOptions"
          [errorMessage]="service.formGroup.controls.countryCode.errors"
          [suppressOptionsTranslate]="true"
          (ngModelChange)="service.onCountryChange($event)"
        />
        <div class="flex flex-row justify-between">
          <div style="width: 49%">
            <app-kz-mobile-date-picker
              formControlName="checkInDate"
              placeholder="Check In"
              [errorMessage]="service.formGroup.controls.checkInDate.errors"
              [minDate]="service.today"
              (ngModelChange)="service.calcCheckInAndCheckOutDaysCount()"
              (afterSelect)="checkOutDateRef.minDate = $event; checkOutDateRef.onOpenCalendar()"
            />
          </div>
          <div style="width: 49%">
            <app-kz-mobile-date-picker
              #checkOutDateRef
              formControlName="checkOutDate"
              placeholder="Check Out"
              [minDate]="service.formGroup.controls.checkInDate.value ?? service.today"
              [errorMessage]="
                !service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount
                  ? service.formGroup.controls.checkOutDate.errors
                  : null
              "
              [errorState]="service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount"
              (ngModelChange)="service.calcCheckInAndCheckOutDaysCount()"
            >
              <ng-template #templateContentFooter>
                @if (service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount) {
                  <div class="error-message text-center">
                    {{ 'The duration of stay should not exceed 30 nights from the Check In date' | translate }}
                  </div>
                }
              </ng-template>
            </app-kz-mobile-date-picker>
          </div>
        </div>
        <div class="flex flex-row">
          <app-kz-mobile-input-form
            placeholder="Number of nights"
            type="number"
            formControlName="checkInAndCheckOutDaysCount"
            [min]="0"
            [max]="30"
            [errorMessage]="null"
            [errorState]="service.hasLimitErrorOndepartureCheckInAndCheckOutDaysCount"
            (keyup)="service.updateCheckInAndCheckOutDaysCount()"
            (blur)="service.calcCheckInAndCheckOutDaysCount()"
          />
          <!-- May be needed in the future -->
          <!-- <div style="width: 49%" class="flex items-center justify-center mb-2">
            <mat-checkbox formControlName="extendedSearch" color="primary">{{ '+/- 3 Days' | translate }}</mat-checkbox>
          </div> -->
        </div>
        <div class="mb-4 w-full">
          <button type="button" class="link-btn" (click)="service.openInstructions()">
            {{ 'Instructions for booking hotels' | translate }}
          </button>
        </div>
        <div class="mb-4 w-full">
          <span class="mb-0">
            {{ 'Cost of inter-hotel and individual transfers' | translate }} -
            <a
              class="font-semibold lowercase"
              href="https://kazunion.com/countries/oae/service/individualnyie-transferyi.html"
              target="_blank"
              >{{ 'Here' | translate }}</a
            >
          </span>
        </div>
        <app-mobile-number-of-passengers-form
          [adults]="service.formGroup.controls.adults.value"
          [children]="service.formGroup.controls.children.value"
          [childrenAgesData]="service.childrenAgesData"
          [childrenAges]="service.formGroup.controls.childrenAges.value"
          [errorMessage]="null"
          (callback)="onNumberOfTouristsCallback($event)"
        />
        <app-kz-mobile-multi-select
          placeholder="City"
          icon="map-marker"
          optionLabel="label"
          optionValue="value"
          optionParentField="parent"
          optionSearchField="tag"
          formControlName="locations"
          [typedToken]="service.locationsTypedToken"
          [options$]="service.locations$"
          [returnOptionsAsValue]="true"
          (ngModelChange)="service.updateHotelFilters()"
        />
        <app-mobile-hotel-filter-mobile-form
          [config]="{
            hotelClasses: {
              options: service.hotelClasses,
              value: service.formGroup.controls.hotelClasses.value
            },
            mealPlans: {
              options: service.mealPlans,
              value: service.formGroup.controls.mealPlans.value
            },
            hotelTypes: {
              options: service.hotelTypes,
              value: service.formGroup.controls.hotelTypes.value
            }
          }"
          (callback)="onHotelFilterCallback($event)"
        />
        <div class="mb-2 flex align-center justify-center">
          <!-- //disabled untill back fix
          <mat-checkbox formControlName="groupByHotel" color="primary">{{
            'Group by hotels' | translate
          }}</mat-checkbox> -->
        </div>
        <app-kz-mobile-multi-select
          placeholder="Hotels"
          icon="bed-outline"
          optionLabel="hotelName"
          optionValue="hotelCode"
          formControlName="hotelCodes"
          [typedToken]="service.availableHotelsTypedToken"
          [options$]="service.availableHotels$"
          [optionFilterPredicate]="service.hotelsOptionFilterPredicate"
        >
          <ng-template #templateOptionLabel let-option>
            <div class="flex flex-row">
              {{ option.hotelName | translate }} <app-kz-stars class="pb-1 pl-2" [stars]="option.hotelClass ?? 0" />
            </div> </ng-template
        ></app-kz-mobile-multi-select>
        <div class="mb-2 flex align-center justify-center">
          <mat-checkbox formControlName="freeSale" color="primary">{{ 'Free sale' | translate }}</mat-checkbox>
        </div>
        <app-mobile-price-and-services-form
          [config]="{
            prices: {
              priceFromValue: service.formGroup.controls.totalPriceFrom.value,
              priceToValue: service.formGroup.controls.totalPriceTo.value
            },
            hotelServices: {
              options: service.hotelServices,
              value: service.formGroup.controls.hotelServices.value
            }
          }"
          (callback)="onPricesAndServicesCallback($event)"
        />
        @if (!formStatus.status) {
          <div class="mt-8 sticky bottom-10">
            <button type="submit" class="w-full" mat-raised-button color="primary" enterkeyhint="go">
              {{ 'Search' | translate }}
            </button>
          </div>
        }
        @if (formStatus.status) {
          <div class="dynamic-modal-footer">
            <div class="dynamic-modal-actions">
              <button type="button" mat-flat-button color="secondary" class="w-1/2" (click)="reset(); scrollTop()">
                {{ 'Reset All' | translate }}
              </button>
              <button
                mat-flat-button
                color="primary"
                class="w-1/2"
                type="submit"
                enterkeyhint="go"
                (click)="submit(); scrollTop()"
              >
                {{ 'Search' | translate }}
              </button>
            </div>
          </div>
        }
      </form>
    </div>
  </ng-template>
  <ng-template #calendarLegensTemplateRef>
    <div class="calendar-legend bg-kz-layout-featured">
      <div>
        <mat-icon class="available" [svgIcon]="'airplane-fill'" />
        {{ 'Places Available' | translate }}
      </div>
      <div>
        <mat-icon class="fever" [svgIcon]="'airplane-fill'" />
        {{ 'Fewer than 6 places' | translate }}
      </div>
      <div>
        <mat-icon class="frequency" [svgIcon]="'airplane-fill'" />
        {{ 'Frequent Flights' | translate }}
      </div>
    </div>
  </ng-template>
}
